/*
  Project: hestiazorg
  Author: Thomas
 */

.c-primary-menu {
  ul.nav-level-1 > li {
    margin-bottom: 0;
    &:first-child > a {
      padding-left: 0;
    }
  }
  .nav-link {
    color: $secondary !important;
    transition: $transition-base;
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    padding-top: 1rem;
    padding-bottom: 0;
    @include media-breakpoint-up(md) {
      padding-top: $spacer * 0.75;
      padding-bottom: $spacer * 0.75;
      padding-right: 1.75rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: $spacer;
      padding-bottom: $spacer;
    }

    &:hover {
      color: $tertiary !important;
    }

    &:active {
      text-decoration: underline;
    }
  }
  .current-menu-item .nav-link {
    color: $tertiary !important;
  }


  // If there are submenu items only first lvl
  &_sub {
    position: relative;
    padding-right: 1rem;
    margin-right: 1.2rem;

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      border-top: 6px solid $white;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      transform: translateY(-50%) rotate(0deg);
      transition: transform 0.25s ease-in-out;
    }
  }

  &_hover {
    position: relative;
    &:hover ,
    &:focus {
      > .c-primary-menu_sub:after {
        transform: translateY(0) rotate(180deg);
      }
      > .c-primary-menu_dropdown {
        visibility: visible;
        padding-top: 5px;
        opacity: 1;
      }
    }
  }

  // The container for submenu relative is body
  &_dropdown {
    transition: $transition-base;
    visibility: hidden;
    opacity: 0;
    width: 200px;
    height: auto;
    padding-top: 0;
    left: 0;
    top: calc(100% - 5px);
    background: $cyan;
    position: absolute;
    z-index: 101;
    box-shadow: 0 20px 20px rgba(0,0,0,0.1);
    border-radius: $border-radius-sm;
  }

}

