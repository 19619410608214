a {
  transition: $transition-base;
  &:hover {
    span {
      border-bottom: 0;
    }
  }
}

a.offset-anchor {
  display: block;
  position: relative;
  top: -170px;
  visibility: hidden;
}

footer {
  a:not(.btn) {
    color: $secondary;
    &:hover {
      color: darken($secondary, 10%);
    }
  }
}
